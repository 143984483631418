




















































































































































































































.fullWidth {
  width: 100%;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.custom-scroll-section {
  overflow-y: auto;
  height: calc(100vh - 108px);
}
