



















































































































































































































.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.buttonEffect {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white;
}
.void-btn {
  height: 52px !important;
  width: 112px;
  font-size: 17px;
}
.showCompleted {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
