





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.fullWidth {
  width: 100%;
}

.blacklistText {
  font-size: 20px;
  //padding-top: 15px !important;
  padding-bottom: 0 !important;
}

.closeButton {
  padding: 8px 16px;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.old-customer {
  margin-left: 7px;
}
