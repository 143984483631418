




























































































































































































































































































































.phone-input {
  ::v-deep .v-input__prepend-outer {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
