
















































































































































































































































.lastUpdatedLabel {
  right: 0px;
  top: -46px;
  position: absolute;
  border: 1px solid #e0e0e0;
  padding: 10px;
  color: red;
  font-weight: bold;
}
