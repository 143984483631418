





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.invoice-heading {
  text-align: center;
  margin-top: 10px;
}

.invoice-title {
  padding-top: 8px;
  font-size: 22px;
  color: var(--v-primary-base);
}

::v-deep {
  .v-input--is-disabled.v-select > .v-input__control > .v-input__slot {
    cursor: default !important;
  }
}

.paymentBox {
  background-color: #f48a03;
  height: 35px;
  width: 100px;
}

.size {
  font-size: 18px;
}

.cursorPointer {
  cursor: pointer;
}

.cursorInitial {
  cursor: initial;
}

.sharedTag {
  right: 0px;
  bottom: 0px;
  position: absolute;
  border-left: 1px solid #b9b9b9;
  border-top: 1px solid #b9b9b9;
  padding: 5px;
}

.selectedPackageItem {
  background-color: var(--v-primary-base) !important;
  color: white;
}

.selectedDifferentHairLength {
  background-color: #f48a03 !important;
  color: white;
}

.chipOrange {
  color: #debc95;
}

.borderBottom {
  border-bottom: 1px solid #b9b9b9;
}

.mt-5.mb-5 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.total-title-style {
  position: absolute;
  white-space: nowrap;
  right: 208px;
  color: var(--v-primary-base);
}
