




































































































































































































































































































































































































































.green-theme {
  color: var(--v-primary-base);
}
.order-status-input ::v-deep .v-text-field__details {
  display: none;
}
.payment-status-input {
  flex: none;
}
.v-file-input {
  margin-top: 108px !important;
  margin-left: 9px !important;
}
