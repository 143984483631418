































.buttonTop {
  margin-top: 32px;
  //width: 30%;
}
.buttonEffect {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white;
}
