






























































































.list {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.grid {
  --grid-layout-gap: 10px;
  --grid-column-count: 8;
  --grid-item--min-width: 300px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
}
