


























































.payrollTable {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  th,
  td {
    text-align: center;
    font-size: 16px !important;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  th {
    text-align: center !important;
  }
  td:first-child {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }
  .headerCell {
    background: #e1551a;
    color: #fff !important;
    border-color: #fff;
  }
  .rowHeader {
    background: #e8f5e9;
    &.dayOff {
      background: #ffebee;
      border: none;
    }
  }
  .totalRow {
    background: #9ccc65;
    color: #fff;
    td {
      border: none;
    }
  }
  .subTotalRow {
    background: #e1551a;
    color: #fff;
    td {
      border: none;
    }
  }
}
