































































.selectedPackageItem {
  background-color: var(--v-primary-base) !important;
  color: white;
}
