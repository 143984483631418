























































































.pdf-container {
  .app-header {
    padding: 16px;
    font-size: 24px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    background-color: #555;
    color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-content {
    background-color: #ddd;
    padding: 24px 16px;
    transition: 0.2s;
  }

  .right {
    float: right;
  }
}
