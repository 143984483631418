











































































































































































































































































































































































@media only screen and (max-width: 850px) {
  .responsiveGap {
    gap: 5px !important;
  }
}

.responsiveGap {
  gap: 25px;
}

.inlineSelect {
  ::v-deep .v-input__slot {
    min-width: 80px;
  }
  ::v-deep .v-select__selection {
    min-width: 24px;
  }
}
