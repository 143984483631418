











































































































































































































































































.tableCol {
  color: var(--v-primary-base);
  font-weight: 500;
}
