




























































































































































































































































































































.overrideVCardText {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
