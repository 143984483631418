





































































































































































































































.stripeClass {
  text-decoration: line-through red;
}

.discountClass {
  margin-left: 5px;
  color: red;
}

.custom-number-input input {
  padding-left: 0 !important;
  text-align: center;
  color: var(--v-primary-base);
}

.buttonEffectTab {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white !important;
}
